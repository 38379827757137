@import '../../../styles/_variable.scss';

.ant-layout-header {
  background-color: #ffffff !important;
  height: 76px !important;
}

.ant-layout-content {
  border-top: 1px solid #e6e8ec;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
  position: fixed;
  z-index: 9999;
  background-color: #ffffff !important;

  &__dropdown {
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 38px;
    flex: none;
    outline: none;
    flex-grow: 0;

    &__btn {
      outline: none;
      background: transparent;
      border: none;
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0px;
      font-family: 'Poppins', sans-serif;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .btn-connected {
    position: relative;
    right: 30px;

    button {
      &:visited,
      &:focus,
      &:hover {
        border-color: transparent;
      }
    }
  }
}

.cover-button {
  background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff 100%);
  padding: 1px;
  border-radius: 12px;
}

.btn-disconnect {
  position: absolute;
  top: 42px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
}
