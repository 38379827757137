@import '../../../styles/_variable';

.input-base {
  border-radius: 100px !important;
  background-color: $background;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2) !important;
  }
}
