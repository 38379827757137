@import '../../styles/_variable.scss';

.main-body {
  height: 100vh;
  overflow: hidden;
}

.ant-layout-content {
  height: 100%;
  background-color: #fff;
  padding: 4px;
  margin-left: 255px;
}

.ant-layout-header {
  padding: 0;
}

.ant-menu-title-content {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.menu-side-bar {
  flex: 0 0 200px;
  max-width: 255px !important;
  min-width: 255px !important;
  width: 255px !important;
  background-color: #1c2237 !important;
  border-radius: 4px;
  position: fixed;
  z-index: 9999;
  height: 100vh;

  .ant-menu {
    background-color: transparent;
  }

  .ant-layout-sider {
    width: 255px !important;
    border-radius: 4px !important;
  }

  .ant-menu-inline {
    border-right: none;
  }

  .ant-menu-item {
    padding-left: 13px !important;
  }

  .ant-menu-item {
    transition: all 0.8s ease !important;

    &:hover {
      border-color: rgba(88, 51, 235, 0.3);
    }

    a {
      color: #ffffff;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .ant-menu-item {
    border-left: 3px solid #c955d6;

    &:after {
      border-color: transparent;
    }
  }

  .ant-menu-item-selected {
    background: rgba(88, 51, 235, 0.2) !important;
  }
}

.menu-side-bar .ant-menu-inline {
  margin-top: 20px;
}

body > iframe {
  pointer-events: none;
}

body > iframe {
  pointer-events: none;
}
