@import '../../../../../styles/variable';

.pair-token {
  width: 100% !important;

  &__box {
    padding: 20px;

    &:last-child {
      border-right: none;
    }

    .ant-form-item-row {
      flex-direction: column !important;
    }

    &__title {
      font-size: 14px;
      line-height: 20px;
      color: $black;
    }
  }

  &__input-token {
    display: flex;
    flex-direction: column;
    // align-items: end;

    &__pair-info {
      display: block;
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
      height: 32px;
    }

    &__btn-delete {
      display: block;
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
      height: 32px;
      width: fit-content;

      align-items: flex-end;
      justify-content: end;
      border: none;
      background: none !important;
      padding: 0;
    }

    &__pair-group {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    &__input-stake {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      align-items: center;

      &__token-status {
        margin-left: 10px;
      }
    }
  }

  .flex-end {
    align-items: flex-end;
  }
}
