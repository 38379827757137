@import '../../../../../styles/_variable.scss';

.container {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 20px;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: $black;
}

.warning {
  margin-top: 8px;
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #f02727;
}
