.input-token {
  width: 100%;

  &__pair-info {
    display: block;
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    height: 32px;
  }

  &__btn-delete {
    border: none;
    background: inherit;
    padding: 0;
  }

  &__pair-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__input-stake {
    display: flex;
    flex-direction: row;
    margin-top: 24px;

    &__invalid {
      height: 50px;
      margin-left: 25px;
      margin-right: 2px;
      position: relative;

      &__token-status-invalid {
        position: absolute;
        left: -15px;
        top: 16px;
      }
    }

    &__token-status {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}
