@import '../../../styles/_variable.scss';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.create-pool {
  background-color: $background-frame;
  height: calc(100vh - 68px);
  overflow-y: auto;

  .row1 {
    padding: 32px;
    justify-content: space-between;
    background: $content-frame;
    margin-bottom: 4px;
    border-radius: 4px;

    &__left {
      display: flex;
      align-items: center;
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }
}

.back-icon {
  cursor: pointer;
  margin-right: 18px;
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: $black;
}

.title-required {
  font-size: 18px;
  line-height: 28px;
  color: $black;
}

.back-title {
  font-size: 26px;
  line-height: 36px;
}

.value {
  font-size: 26px;
  line-height: 36px;
}

.allow-staking {
  font-size: 14px;
  line-height: 20px;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
}

.stop-staking {
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.switch-custom {
  transform: rotate(180deg);
}

form {
  padding: 4px;
}

.content {
  background: $content-frame;
  margin-bottom: 4px;
  border-radius: 4px;

  .box {
    padding: 20px;

    &.basic-info {
      &::after {
        content: '';
        position: absolute;
        top: 32px;
        left: 0;
        height: calc(100% - 64px);
        width: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .ant-form-item-row {
      flex-direction: column !important;
    }
  }

  .pair-box {
    padding: 0 20px 32px 20px;

    .ant-form-item-row {
      flex-direction: column !important;
    }
  }

  .box-row {
    padding: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    &__tittle {
      font-size: 14px;
      line-height: 20px;
      margin: 0px 10px;
    }

    &__item {
      display: flex;
      align-items: center;
    }
  }

  .pool-index {
    display: flex;

    &__info {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
    }
  }

  .liquidity {
    display: flex;

    &__info {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
    }
  }

  .input-base {
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px !important;
    height: 50px;
    font-size: 16px;
  }

  .radio-button-custom {
    display: flex;
  }
}

.group-button {
  display: flex;
  justify-content: flex-end !important;
}

.text-required {
  margin-left: 3px;
  color: red;
}

label {
  width: 100%;
}

.ant-layout-content {
  background-color: #e5e5e5 !important;
}

.btn-create {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 53px;
  border-radius: 30px;
  margin-bottom: 42px;
  margin-top: 16px;
  margin-right: 32px;
  background: linear-gradient(
    131.66deg,
    #f649b8 8.75%,
    #8b66ff 100%
  ) !important;
  color: $white-content !important;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Poppins-Bold';
}

.divider-content {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.btn-add-token {
  margin: 20px;
  height: 50px;
  width: 100%;
  margin-top: 24px;
  background: inherit;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.token-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 65px;
  width: inherit;
  padding: 6.5px 15px;
  background: rgba(28, 34, 55, 0.05);
  border-radius: 7px;
  margin-top: 24px;
}

.token-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;

  &__name {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    justify-content: center;
    width: 33%;
  }
}

.text-logo {
  text-align: left;
  width: max-content;
  margin: auto;
}

.name-token {
  font-size: 14px;
  line-height: 20px;
}

.value-token {
  font-size: 18px;
  line-height: 28px;
}
