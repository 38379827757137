.input-ratio {
  // display: flex;
  &__form {
    margin-bottom: 0px;
  }
  &__container {
    display: flex;
    align-items: center;
  }
}
