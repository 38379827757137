.font-700 {
  font-weight: 700;
  font-style: normal;
}

.font-600 {
  font-weight: 600;
  font-style: normal;
}

.font-500 {
  font-weight: 500;
  font-style: normal;
}

.font-400 {
  font-weight: 400;
  line-height: normal;
}

.mt-lg {
  margin-top: 24px;
}
