@import '../../../styles/_variable.scss';

.btn-primary {
  background: $blue-color !important;
  color: $white-content !important;
}

.btn-secondary {
  background: #606060 !important;
  color: $white-content !important;
}

.btn-outline {
  outline: 1px solid $blue-color !important;
  color: $blue-color !important;
  background: inherit !important;
}

.btn-outline-gray {
  border: 2px solid #c9c9c9 !important;
  color: $black !important;
  background: inherit !important;
}

.btn-warning {
  color: $white-content !important;
  background: #eb3333 !important;
}
