@import '../../../styles/_variable.scss';

.tabs-custom {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $blue-color;
  }

  .ant-tabs-ink-bar {
    background: $blue-color;
  }
}
