.token-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 65px;
  width: inherit;
  padding: 6.5px 15px;
  background: rgba(28, 34, 55, 0.05);
  border-radius: 7px;
  margin-top: 24px;

  &__icon-token {
    width: 36px;
    height: 36px;
  }

  &__icon-token-lp {
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    margin-right: 10px;

    &__first {
      width: 36px;
      height: 36px;
    }

    &__second {
      display: inline-block;
      margin-right: 8px;
      width: 36px;
      height: 36px;
      position: absolute;
      left: 50%;
    }
  }
}

.token-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;

  &__name {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    justify-content: center;
    width: 33%;
  }
}

.text-logo {
  text-align: left;
  width: max-content;
  margin: auto;
}

.name-token {
  font-size: 14px;
  line-height: 20px;
}

.value-token {
  font-size: 18px;
  line-height: 28px;
}
