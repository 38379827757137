$background: #fafbfe;
$background-content: #fef4fb;
$color-required: #f02727;
$blue-color: #5833eb;
$circle-color: #d9d9d9;
$black: #000000;
$black-primary: #23262f;
$content-frame: #fcfcfd;
$background-frame: #f1f1f1;
$white-content: #ffffff;

%flex-center-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
