@import '../../../styles/_variable.scss';

.drop-down-custom {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  width: 156px;
  height: 36px;
  background: rgba(28, 34, 55, 0.1);
  border-radius: 40px;
  flex: none;
  flex-grow: 0;
  margin: 0px 8px;

  &__button {
    // width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    background: rgba(28, 34, 55, 0.1);
    height: 40px;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      border: 1px solid #d9d9d9;
      background: rgba(28, 34, 55, 0.1);
    }

    &__left {
      display: flex;
      align-items: center;

      span {
        color: #1c2237;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: 11px;
      }
    }
  }
}

.wrapper-option {
  flex-direction: row;
  padding: 8px 12px;
  gap: 8px;
  width: 162px;

  background: #ffffff;
  box-shadow: 0px 33px 100px rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  flex: none;
  flex-grow: 0;
  z-index: 9999;

  .ant-checkbox + span {
    color: #1c2237;
  }

  .ant-checkbox-inner {
    border: 1px solid;
    border-image-slice: 1 !important;
    border-width: 1px;
    border-image-source: linear-gradient(
      131.66deg,
      #f649b8 8.75%,
      #8b66ff 100%
    );
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(131.66deg, #f649b8 8.75%, #8b66ff 100%);
  }

  &__All {
    display: flex;
    flex-direction: row;
    padding: 8px 0 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 0 !important;
  }

  &__Group {
    display: flex !important;
    flex-direction: column;
    padding: 0 0 16px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    &__Check {
      margin: 8px 0 !important;
    }
  }
}
