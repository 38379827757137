@import '../../styles/_variable.scss';

.login-page {
  @extend %flex-center-center;
  flex-direction: column;
  background-color: #faf9ff;
  width: 100%;
  height: 100%;

  &__logo {
    margin-bottom: 20px;
  }

  &__container {
    @extend %flex-center-center;
    flex-direction: column;
    background-color: #ffffff;
    padding: 32px 144px;
    min-width: 498px;
    min-height: 270px;
    border: 1px solid #ececf6;

    box-shadow: 0px 10px 40px rgba(112, 125, 150, 0.05);
    border-radius: 16px;

    &__firework {
      margin-bottom: 8px;
    }

    &__txt-content {
      font-family: Poppins-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 8px;
    }

    &__txt-notification {
      font-family: 'Poppins-Regular';
      font-size: 16px;
      line-height: 24px;
      color: $black;
      margin-bottom: 32px;
    }

    &__lock {
      @extend %flex-center-center;
      flex-direction: column;

      &__btn-clickMe {
        border: none;
        background: none;
      }

      div a {
        font-size: 14px;
        line-height: 20px;
        color: #ff0000;
        text-decoration: underline;
      }
    }
  }

  .buttonCustom {
    font-size: 16px;
    font-weight: 600;
  }
}

.txt-warning {
  font-family: 'Poppins-Regular';
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #ff0000;
  text-align: center;
}

.link {
  cursor: pointer;
}

.modal {
  @extend %flex-center-center;
  flex-direction: column;
  min-width: 400px;
  min-height: 250px;

  &__title {
    font-family: 'Poppins-Bold';
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 32px;
  }

  &__network {
    &__btn-change-network {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 17px 20px;

      min-width: 384px !important;
      min-height: 70px !important;

      background: #ffffff;
      border: 1px solid rgba(28, 34, 55, 0.3);
      border-radius: 10px !important;

      font-family: 'Poppins-Bold';
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }
  }

  &__network button:first-child {
    margin-bottom: 24px;
  }
}
