.table-pool {
  height: 100%;
}

.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .ant-table {
        height: max-content;

        &.ant-table-empty {
          height: 100%;

          .ant-table-container {
            height: 100%;

            .ant-table-content {
              height: 100%;

              table {
                height: 100%;
              }
            }
          }
        }

        .ant-table-container {
          height: max-content;

          .ant-table-content {
            height: max-content;
          }
        }
      }

      .ant-table-pagination {
        padding: 16px 22px 20px 0;
      }
    }
  }
}

.ant-table-content {
  .table-title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #353945;
  }

  .flex {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 49px));
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .text-no-record {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1c2237;
  }

  tr {
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #23262f;
    }
  }
}
