@import '../../styles/_variable.scss';

[data-type='complete'] {
  --backgroundColor: #e8fff0;
  --borderColor: #58bd7d;
}

[data-type='error'] {
  --backgroundColor: #ffe6de;
  --borderColor: #ff6838;
}

.ant-message-notice {
  width: fit-content;
  float: right;
  padding: 0;
  margin: 20px 40px 0 0;

  .ant-message-notice-content {
    padding: 0;
    background: unset;
    box-shadow: unset;
  }
}

.ant-message-info .anticon,
.ant-message-loading .anticon > span {
  color: #777e90;
}

.message-alert {
  display: flex;
  align-items: center;
  padding: 0;
  padding-right: 10px;

  box-sizing: border-box;
  background: var(--backgroundColor);
  border: 1px solid var(--borderColor);

  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #353945;

  border-radius: 8px;

  @media screen and (max-width: 576px) {
    width: 310px;
    font-size: 9px;
  }

  &__message-content {
    padding-right: 20px;
    text-align: left;
    display: block;
  }
}

.message-success {
  @extend %flex-center-center;
}
