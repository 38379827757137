@font-face {
  font-family: 'Poppins-Regular';
  src: url(./fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(./fonts/Poppins-Bold.ttf);
}

*,
:root,
html {
  font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
}
