@import '../../styles/_variable.scss';

.ant-layout-has-sider {
  min-height: max-content !important;
}

.detail-pool {
  background-color: $background-frame;
  height: calc(100vh - 68px);
  overflow-y: auto;
  padding-bottom: 40px;

  &__flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .ant-input-prefix {
    margin-right: 10px;
  }

  .row1 {
    padding: 32px;
    justify-content: space-between;
    background: $content-frame;
    margin-bottom: 4px;
    border-radius: 4px;

    &__left {
      display: flex;
      align-items: center;
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }

  .back-icon {
    cursor: pointer;
    margin-right: 18px;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: $black;
  }

  .title-required {
    font-size: 18px;
    line-height: 28px;
    color: $black;
  }

  .pool-name {
    font-size: 26px;
    line-height: 60px;
    font-weight: 700;
    color: $black-primary;
    line-height: 36px;
  }

  .pool-address {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #1c2237;

    img {
      height: 18px;
      cursor: pointer;
    }
  }

  .value {
    font-size: 26px;
    line-height: 36px;
  }

  .allow-staking {
    font-size: 14px;
    line-height: 20px;
    font-size: 14px;
    line-height: 22px;
    margin-right: 10px;
  }

  .stop-staking {
    font-size: 14px;
    line-height: 20px;
    font-size: 14px;
    line-height: 22px;
    margin-left: 10px;
  }

  .switch-custom {
    transform: rotate(180deg);
  }

  form {
    padding: 4px;
  }

  .content {
    background: $content-frame;
    margin-bottom: 4px;
    border-radius: 4px;

    .box {
      padding: 20px;

      &:last-child {
        border-right: none;
      }

      &.basic-info {
        &::after {
          content: '';
          position: absolute;
          top: 32px;
          left: 0;
          height: calc(100% - 64px);
          width: 1px;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      .ant-form-item-row {
        flex-direction: column !important;
      }
    }

    .pool-index {
      display: flex;

      &__info {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
      }
    }

    .liquidity {
      display: flex;

      &__info {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
      }
    }

    .input-base {
      background: #f5f5f5;
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px !important;
      height: 50px;
    }

    .radio-button-custom {
      display: flex;
    }
  }

  .group-button {
    display: flex;
    justify-content: flex-end !important;
  }

  .text-required {
    margin-left: 3px;
    color: red;
  }

  label {
    width: 100%;
  }

  .ant-layout-content {
    background-color: #e5e5e5 !important;
  }

  .btn-create {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 53px;
    border-radius: 30px;

    background: linear-gradient(
      131.66deg,
      #f649b8 8.75%,
      #8b66ff 100%
    ) !important;
    color: $white-content !important;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Poppins-Bold';
  }

  .divider-content {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
    margin-top: 24px;
    align-items: center;
  }

  .btn-delete {
    border: none;
    background: inherit;
    padding-right: 0;
  }

  .btn-add-token {
    margin: 20px;
    height: 50px;
    width: 100%;
    margin-top: 24px;
    background: inherit;
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .pair-info {
    display: block;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    color: $black;
  }

  .token-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    height: 65px;
    width: inherit;
    padding: 6.5px 15px;
    background: rgba(28, 34, 55, 0.05);
    border-radius: 7px;
    margin-top: 24px;

    &__icon-token-lp {
      position: relative;
      width: 33px;
      height: 33px;
      display: flex;
      align-items: center;
      margin-right: 10px;

      &__first {
        width: 33px;
        height: 33px;
      }

      &__second {
        display: inline-block;
        margin-right: 8px;
        width: 33px;
        height: 33px;
        position: absolute;
        left: 50%;
      }
    }

    img {
      width: 33px;
      height: 33px;
    }
  }

  .token-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

    &__name {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      justify-content: center;
      width: 33%;
    }
  }

  .text-logo {
    text-align: left;
    width: max-content;
    margin: auto;
  }

  .name-token {
    font-size: 14px;
    line-height: 20px;
  }

  .value-token {
    font-size: 18px;
    line-height: 28px;
  }

  .ant-input-affix-wrapper-disabled {
    background: #f5f5f5 !important;
  }

  .ant-input-disabled {
    background: #f5f5f5 !important;
    color: $black;
    font-size: 16px;
  }

  .flex {
    display: flex;
  }

  .dynamic-percent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 14px;
    gap: 10px;
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 10px 5px 10px 0;
  }

  .pair-name {
    white-space: nowrap;
  }

  .percent {
    display: flex;
    align-items: center;
    background: rgba(28, 34, 55, 0.1);
    border-radius: 4px;
    padding: 8px 8px 6px;
    gap: 10px;
  }

  .apr-percent {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
    padding: 8px 8px 6px;
    max-width: max-content;
    border-radius: 4px;
    background: rgba(28, 34, 55, 0.1);
    line-height: 16px;
    font-size: 12px;
    color: #1c2237;
  }

  .input-stake {
    .ant-input-disabled {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }

  .box-ratio {
    padding: 20px 20px 20px 0px;
    display: flex;
    align-items: center;

    &__tittle {
      font-size: 14px;
      line-height: 20px;
      margin: 0px 10px;
    }

    input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px;
      gap: 10px;

      width: 160px;
      height: 50px;

      background: #ffffff;
      border-radius: 12px;
    }

    .symbol {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin-left: 10px;
      height: 20px;
    }

    .two-dot {
      font-weight: 700;
      font-size: 14px;
      margin-left: 10px;
      line-height: 20px;
    }

    &:last-child {
      .two-dot {
        display: none;
      }

      padding-left: 0;
    }
  }
}

.apr-container {
  display: flex;
  justify-content: center;

  &__apr {
    min-width: 75px;
    text-align: right;
  }

  &__typContainer {
    min-width: 50px;
    margin-left: 5px;

    &__poolType {
      border-radius: 4px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: auto;
      height: 16px;
      margin-top: 2px;
      padding: 0px 2px;
      text-transform: lowercase;
      width: fit-content;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .dynamic {
    background: rgba(148, 99, 248, 0.2);
    color: #9363f8;
  }

  .fixed {
    background: rgba(244, 73, 184, 0.2);
    color: #f648b8;
  }
}

.ant-layout-content {
  background-color: #e5e5e5 !important;
}

.pool-management {
  height: calc(100vh - 78px);

  .ant-table-cell {
    &:before {
      background-color: transparent !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px;
      gap: 20px;
      background: #fcfcfd;
      border-radius: 4px;

      .text {
        font-weight: 700;
        font-size: 26px;
        line-height: 28px;
        color: $black-primary;
      }

      .right {
        display: flex;
        // justify-content: space-between;
        align-items: center;
      }

      button {
        font-weight: 600;
      }
    }

    .ant-table-wrapper {
      background-color: #ffffff;
      margin: 0px 10px;
      border-radius: 4px;
    }

    .search {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 256px;
      height: 40px;
      padding: 10px 12px 10px 0px;
      gap: 18px;
      border: 1px solid rgba(28, 34, 55, 0.2);
      border-radius: 40px;
      flex-grow: 0;

      input {
        &::placeholder {
          color: #1c2237;
          opacity: 0.3;
        }
      }
    }

    .buttonCustom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 15px 123px;
      gap: 10px;
      width: 148px;
      height: 36px;
      background: linear-gradient(
        131.66deg,
        #f649b8 8.75%,
        #8b66ff 100%
      ) !important;
      border-radius: 30px;
      flex: none;
      flex-grow: 0;
      cursor: pointer;

      &:hover {
        border-color: transparent !important;
      }
    }

    .button-status {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 10px;
      float: right;
      height: 30px;

      border-radius: 4px;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }

    .button-end {
      background: rgba(28, 34, 55, 0.1);

      span {
        color: #1c2237;
        font-style: normal;
        opacity: 0.5;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .button-live {
      background: rgba(246, 72, 184, 0.2);

      span {
        color: #f648b8;
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .ant-pagination-item {
      &:hover {
        border: 1px solid #f648b8;

        a {
          color: #f648b8;
        }
      }
    }

    .thee-dot {
      white-space: nowrap;
      width: 138px;
      overflow: hidden;
      text-overflow: ellipsis;
      .date-tag {
        margin-left: 5px;
        display: inline-block;
        padding: 2px 6px;
        font-size: 12px;
        height: 100%;
        background: #f4f4f4;
        border-radius: 4px;
      }
    }

    .ant-pagination-item-active {
      border: 1px solid #f648b8;
      border-radius: 4px;
      background: #ffffff;

      a {
        color: #f648b8;
      }
    }
  }
}
